







































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IAPIKeys, IUser, PayloadState } from '@/types/types';
const usersModule = namespace('users');

@Component({
  name: 'GenerateAPIKeyDialog',
  components: {
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    EmptyPage: loadWidget('pages/EmptyPage'),
  },
  filters: {
    mask(value: string, dialogState: number) {
      return !dialogState
        ? value
        : new Array(value?.length)
            .fill(0)
            .map(() => '*')
            .join('');
    },
  },
})
export default class GenerateAPIKeyDialog extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getTrackDialogAction') trackDialogAction!: number;
  @usersModule.Getter('getResetFormValues') resetFormValues!: IUser;
  @usersModule.Getter('getSearchResults') subAccounts!: ReadonlyArray<IUser>;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;
  @Prop({ default: require('@/assets/images/key.png') }) image!: string;
  @Prop({ default: null }) apiKeyDetails!: IAPIKeys;

  @Emit('generateKey')
  generate(): PayloadState {
    return {
      idx: 'edit',
      state: false,
    };
  }

  @Emit('revokeKey')
  revokeKey(): string {
    return this.apiKeyDetails.uuid;
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'edit',
      state: false,
    };
  }

  @Emit('copy')
  copyCredentials(
    credential: string | null,
    field?: string
  ): { credential: string | null; field?: string } {
    return { credential, field };
  }
}
